<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';

import NoticeManageTableForm from './components';

formCreate.component('NoticeManageTableForm', NoticeManageTableForm);

export default {
  extends: Form,
  data() {
    return {
      rule: [],
    };
  },
  components: {
    formCreate: formCreate.$form(),
  },
  async mounted() {
    this.rule = await this.getFormRule('dms_add_form');
    this.rule.map((v) => {
      const rowData = v;
      if (rowData.field === 'files') {
        rowData.props.showFileList = true;
        rowData.props.uploadType = 'file';
        rowData.props.listType = 'text';
        rowData.props.fileMsg = '只能上传pdf/word/excel/ppt/png/jpg文件';
        rowData.props.accept = '.pdf,.doc,.docx,.xlsx,.xls,.ppt,.pptx,.png,.jpg';
        if (this.formConfig.code === 'view') {
          rowData.props.disabled = true;
        }
      } else if (rowData.field === 'endTime' || rowData.field === 'startTime') {
        rowData.props = {
          ...rowData.props,
          type: 'datetime',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          relation: {
            beginField: 'startTime',
            endField: 'endTime',
          },
        };
      } else if (rowData.field === 'list') {
        rowData.props = {
          functionCode: '',
          selection: [],
          idKey: 'id',
          notView: this.formConfig.code !== 'view',
        };
        rowData.value = {
          orgs: [],
          nonOrgs: [],
          customers: [],
          nonCustomers: [],
        };
      } else if (rowData.field === 'content') {
        rowData.props = {
          ...rowData.props,
          notView: this.formConfig.code === 'view',
        };
      }
      return rowData;
    });
    this.reload(this.rule);
    if (this.formConfig.selection[0]) {
      this.getData();
    }
  },
  methods: {
    // 获取表单数据
    getData() {
      request
        .get(`/dms/m/notice/findById/${this.formConfig.selection[0]}`, {
          id: this.formConfig.selection[0],
        })
        .then((res) => {
          res.result.customers.map((v) => {
            const rowData = v;
            rowData.customerCode = v.cusCode;
            rowData.customerName = v.cusName;
            return rowData;
          });
          res.result.nonCustomers.map((v) => {
            const rowData = v;
            rowData.customerCode = v.cusCode;
            rowData.customerName = v.cusName;
            return rowData;
          });
          const data = {
            ...res.result,
            type: `${res.result.type}`,
            list: {
              orgs: res.result.orgs,
              nonOrgs: res.result.nonOrgs,
              customers: res.result.customers,
              nonCustomers: res.result.nonCustomers,
            },
          };
          this.setValue(data);
        });
    },
    // 提交
    async submit() {
      const params = {
        ...this.getFormData(),
        id: this.formConfig.selection[0] || '',
        orgs: this.getFormData().list.orgs.map((v) => {
          const rowData = v;
          rowData.customerName = '';
          rowData.customerCode = '';
          rowData.containFlag = 1;
          delete rowData.id;
          return rowData;
        }),
        nonOrgs: this.getFormData().list.nonOrgs.map((v) => {
          const rowData = v;
          rowData.customerName = '';
          rowData.customerCode = '';
          rowData.containFlag = 0;
          delete rowData.id;
          return rowData;
        }),
        customers: this.getFormData().list.customers.map((v) => {
          const rowData = v;
          rowData.orgCode = '';
          rowData.orgName = '';
          rowData.cusCode = rowData.customerCode;
          rowData.cusName = rowData.customerName;
          rowData.containFlag = 1;
          delete rowData.id;
          return rowData;
        }),
        nonCustomers: this.getFormData().list.nonCustomers.map((v) => {
          const rowData = v;
          rowData.orgCode = '';
          rowData.orgName = '';
          rowData.cusCode = rowData.customerCode;
          rowData.cusName = rowData.customerName;
          rowData.containFlag = 0;
          delete rowData.id;
          return rowData;
        }),
      };
      delete params.list;

      let path = '';
      if (this.formConfig.selection[0]) {
        path = '/dms/m/notice/edit';
      } else {
        path = '/dms/m/notice/add';
      }
      request.post(path, params).then((res) => {
        if (res.success) {
          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
      return true;
    },
    // // 编辑赋值
    // initEditValue() {
    //   const submitType = this.formConfig.type;
    //   if (submitType === 'add') {
    //     this.resetFields();
    //   } else if (this.formConfig.row) {
    //     request
    //       .post('/mdm/mdmfunctionsub/query', { functionCode: this.formConfig.row })
    //       .then((res) => {
    //         this.setValue(res.result);
    //       });
    //   }
    // },
  },
};
</script>
