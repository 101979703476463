<script>
import TablePage from '../../../../../../components/table_page';
import Form from '../form';
// import request from '../../../../../../utils/request';

export default {
  extends: TablePage,
  components: {
    Form,
  },
  props: {
    parentCode: String,
  },
  data() {
    return {
      requestUrl: '',
      formConfig: {},
      selection: [],
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },

  async created() {
    const columns = await this.getConfigList('dms_table_page');
    columns.map((v) => {
      const rowData = v;
      if (rowData.field === 'createDate') {
        rowData.formatter = ({ row }) => `${row.createDate} ${row.createDateSecond}`;
      }
      return rowData;
    });
  },
  methods: {

    modalClick({ val, row }) {
      if (val.code === 'edit') {
        // if (this.selection.length === 1) {
        this.modalConfig.title = '编辑';
        this.formConfig.selection = [row.id];
        this.openFull();
      } else if (val.code === 'add') {
        this.modalConfig.title = '新增';
        this.formConfig.selection = [];
        this.openFull();
      } else if (val.code === 'view') {
        this.modalConfig.title = '查看';
        this.formConfig.selection = [row.id];
        this.openFull();
      }
      return true;
    },
  },
};
</script>
